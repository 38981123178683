import React from 'react';
import { Icon } from '@iconify/react-with-api';
import { oneLine } from 'common-tags';
import Select, { components } from 'react-select';
import { IInputProps } from '../../../interface/ISelect';
import AsyncSelect from 'react-select/async';
import { useIntl } from 'react-intl';
import { ChevronDown } from 'react-iconly';
import { useRouter } from 'next/router';
const style = {
  container: (base: any) => ({
    ...base,
    border: '0',
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: '900',
  }),
  menu: (base: any) => ({
    ...base,
    border: '0',
    background: '#FFFFFF',
    boxShadow: '0px -1px 10px rgba(154, 170, 207, 0.15), 0px 16px 40px rgba(154, 170, 207, 0.15)',
    borderRadius: '20px',
    overflow: 'hidden',
    zIndex: '900',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    border: 0,
    cursor: 'pointer',
    background: state.isDisabled
      ? null
      : state.isSelected
        ? 'var(--whiteish)'
        : state.isFocused
          ? 'var(--whiteish)'
          : 'white',
    color: state.isSelected ? 'var(--dark)' : 'var(--body)',
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeigth: 1.4,
  }),
  placeholder: (base: any) => ({
    ...base,
    color: 'var(--body)',
    fontSize: '1rem',
    '@media only screen and (min-width: 768px)': {
      ...base['@media only screen and (min-width: 768px)'],
      fontSize: '.875rem',
    },
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: '0 1rem .5rem 1rem',
  }),
  singleValue: (base: any) => ({
    ...base,
    color: 'var(--dark)',
    fontWeight: 600,
    margin: '0',
    fontSize: '1.125rem',
    '@media only screen and (min-width: 768px)': {
      ...base['@media only screen and (min-width: 768px)'],
      fontSize: '1rem',
    },
  }),
  multiValue: (base: any) => ({
    ...base,
    padding: 2,
    borderRadius: 100,
    background: 'var(--primary)',
    color: '#fff',
    display: 'flex',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: '#fff',
    fontSize: '1rem',
    '@media only screen and (min-width: 768px)': {
      ...styles['@media only screen and (min-width: 768px)'],
      fontSize: '.75rem',
    },
    fontWeight: 600,
    maxWidth: '120px',
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: '#fff',
    ':hover': {
      color: 'white',
    },
  }),
  control: (base: any) => ({
    ...base,
    background: 'transparent',
    border: '0',
    borderBottom: `2px solid var(--light)`,
    borderRadius: '0',
    outline: 'none',
    boxShadow: 'none',
    transition: 'all .3s ease-in-out',
    '&:hover': {
      borderBottomColor: `var(--primary)`,
    },
  }),
  input: (base: any) => ({
    ...base,
    '& input': {
      border: '0!important',
      width: '100%',
      outline: 'none!important',
      boxShadow: 'none!important',
    },
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: 'var(--body)',
    transition: 'all .3s ease-in-out',
    ':hover': {
      cursor: 'pointer',
      color: 'var(--accent-hue)',
    },
  }),
  clearIndicator: (base: any) => ({
    ...base,
    color: 'var(--primary)',
    ':hover': {
      cursor: 'pointer',
    },
  }),
};

const styleLightBorder = {
  ...style,
  control: (base: any) => ({
    ...base,
    background: 'transparent',
    border: '0',
    borderBottom: `2px solid #F3F3F3`,
    borderRadius: '0',
    outline: 'none',
    boxShadow: 'none',
    transition: 'all .3s ease-in-out',
    '&:hover': {
      borderBottomColor: `var(--primary)`,
    },
  }),
  placeholder: (base: any) => ({
    ...base,
    margin: 0,
    fontSize: '1rem',
    fontWeight: '400'
  }),
};
const styleTransparent = {
  container: (base: any) => ({
    ...base,
    border: '0',
    background: 'transparent',
  }),
  ValueContainer: (base: any) => ({
    ...base,
    background: 'transparent',
  }),
  singleValue: (base: any) => ({
    ...base,
    padding: 2,
    color: '#fff',
  }),
  placeholder: (base: any) => ({
    ...base,
    padding: 2,
    color: '#fff',
  }),
  multiValue: (base: any) => ({
    ...base,
    padding: 2,
    borderRadius: 100,
    background: 'var(--primary)',
    color: '#fff',
    display: 'flex',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: '#fff',
    maxWidth: '120px',
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: '#fff',
    ':hover': {
      color: 'white',
    },
  }),
  control: (base: any) => ({
    ...base,
    border: '0',
    borderBottom: `2px solid var(--light)`,
    borderRadius: '0',
    outline: 'none',
    background: 'transparent',
    boxShadow: 'none',
    color: '#fff',
    '&:hover, &:focus': {
      borderBottomColor: `var(--primary)`,
    },
  }),
  input: (base: any) => ({
    ...base,
    '& input': {
      border: '0!important',
      width: '100%',
      color: '#fff',
      outline: 'none!important',
      boxShadow: 'none!important',
    },
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: 'var(--body)',
    ':hover': {
      cursor: 'pointer',
    },
  }),
  clearIndicator: (base: any) => ({
    ...base,
    color: 'var(--primary)',
    ':hover': {
      cursor: 'pointer',
    },
  }),
};

const styleDark = {
  ...styleTransparent,
  control: (base: any) => ({
    ...base,
    padding: 2,
    border: '0',
    borderBottom: `2px solid rgba(133, 141, 150, 0.4)`,
    borderRadius: '0',
    outline: 'none',
    background: 'transparent',
    boxShadow: 'none',
    color: '#fff',
    '&:hover': {
      borderBottom: `2px solid #F64747`,
    },
  }),
  container: (base: any) => ({
    ...base,
  }),
  valueContainer: (base: any) => ({
    ...base,
  }),
  placeholder: (base: any) => ({
    ...base,
    margin: 0,
    color: '#C1C5CA',
    fontSize: '1rem',
    fontWeight: '400'
  }),
  input: (base: any) => ({
    ...base,
    color: '#fff',
    '& input': {
      border: '0!important',
      width: '100%',
      color: '#fff',
      outline: 'none!important',
      boxShadow: 'none!important',
    },
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: '#E4DEDE',
    ':hover': {
      cursor: 'pointer',
      color: '#fff',
    },
  }),
  menu: (base: any) => ({
    ...base,
    border: '0',
    color: '#fff',
    background: '#1A1A1A',
    boxShadow: '0px -1px 10px 0px #9AAACF26',
    borderRadius: '20px',
    overflow: 'hidden',
    zIndex: '900',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    border: 0,
    cursor: 'pointer',
    background: state.isDisabled
      ? null
      : state.isSelected
        ? '#080707'
        : state.isFocused
          ? '#080707'
          : '#1A1A1A',
    color: state.isSelected ? '#ffffff' : '#E4DEDE',
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeigth: 1.4,
  }),
};

const styleMonday = {
  ...styleTransparent,
  control: (base: any) => ({
    ...base,
    marginRight: '16px',
    padding: 2,
    border: '0',
    borderBottom: `2px solid rgba(133, 141, 150, 0.4)`,
    borderRadius: '0',
    outline: 'none',
    background: 'transparent',
    boxShadow: 'none',
    color: '#fff',
    '&:hover': {
      borderBottom: `2px solid #F64747`,
    },
  }),
  container: (base: any) => ({
    ...base,
  }),
  valueContainer: (base: any) => ({
    ...base,
  }),
  placeholder: (base: any) => ({
    ...base,
    margin: 0,
    color: '#C1C5CA',
    fontSize: '0.9rem',
    fontWeight: '400'
  }),
  input: (base: any) => ({
    ...base,
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: '400',
    '& input': {
      border: '0!important',
      width: '100%',
      color: '#fff',
      outline: 'none!important',
      boxShadow: 'none!important',
    },
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: '#F64747',
    ':hover': {
      cursor: 'pointer',
      color: '#F64747',
    },
  }),
  menu: (base: any) => ({
    // ...base,
    paddingTop: '10px',
    paddingRight: '16px',
    boxSizing: 'border-box',
    border: '0',
    color: '#fff',
    background: '#1A1A1A',
    // boxShadow: '0px -1px 10px 0px #9AAACF26',
    borderRadius: '10px',
    overflow: 'hidden',
    zIndex: '900',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    padding: '16px',
    marginBottom: '4px',
    borderRadius: '10px',
    border: '1px solid #C1C5CA26',
    cursor: 'pointer',
    background: state.isDisabled
      ? null
      : state.isSelected
        ? '#080707'
        : state.isFocused
          ? '#080707'
          : '#1A1A1A',
    color: state.isSelected ? '#ffffff' : '#E4DEDE',
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeigth: 1.4,
  }),
};

const styleMondayDark = {
  ...styleTransparent,
  control: (base: any) => ({
    ...base,
    marginRight: '16px',
    padding: 2,
    border: '0',
    borderBottom: `2px solid rgba(133, 141, 150, 0.4)`,
    borderRadius: '0',
    outline: 'none',
    background: 'transparent',
    boxShadow: 'none',
    color: '#fff',
    '&:hover': {
      borderBottom: `2px solid #F64747`,
    },
  }),
  container: (base: any) => ({
    ...base,
  }),
  valueContainer: (base: any) => ({
    ...base,
  }),
  placeholder: (base: any) => ({
    ...base,
    margin: 0,
    color: '#C1C5CA',
    fontSize: '0.9rem',
    fontWeight: '400'
  }),
  input: (base: any) => ({
    ...base,
    fontSize: '0.9rem',
    fontWeight: '400',
    '& input': {
      border: '0!important',
      width: '100%',
      outline: 'none!important',
      boxShadow: 'none!important',
    },
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: 'var(--body)',
    transition: 'all .3s ease-in-out',
    ':hover': {
      cursor: 'pointer',
      color: 'var(--accent-hue)',
    },
  }),
  menu: (base: any) => ({
    // ...base,
    paddingTop: '10px',
    paddingRight: '16px',
    boxSizing: 'border-box',
    border: '0',
    color: '#fff',
    background: '#FFFFFF',
    borderRadius: '10px',
    overflow: 'hidden',
    zIndex: '900',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    padding: '16px',
    marginBottom: '4px',
    borderRadius: '10px',
    border: '1px solid #C1C5CA26',
    cursor: 'pointer',
    background: state.isDisabled
      ? null
      : state.isSelected
        ? 'var(--whiteish)'
        : state.isFocused
          ? 'var(--whiteish)'
          : 'white',
    color: state.isSelected ? 'var(--dark)' : 'var(--body)',
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeigth: 1.4,
  }),
};


const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown set="light" size="small" />
    </components.DropdownIndicator>
  );
};
const InputSelect: React.FC<IInputProps> = ({
  isClearable,
  selName,
  classNamePrefix,
  isMulti,
  maxMenuHeight = 200,
  placeholderText,
  options,
  defaultValue,
  disabled,
  isAsync,
  innerRef,
  validation,
  fullWidth,
  label,
  borderLight,
  getAsyncOptions,
  onChange,
  value,
  hint,
  dataCy,
  isMonday = false,
  isTransparent = false,
  isTransparentWhite = false,
}: IInputProps) => {
  const intl = useIntl();
  const [innerError, setInnerError] = React.useState('');
  const [labelUp, setLabelUp] = React.useState(false);
  const [val, setVal] = React.useState(defaultValue);
  const [themeMode, setThemeMode] = React.useState<string | null>(null);
  const router = useRouter();
  const { error } = validation;
  React.useEffect(() => {
    if (error) {
      setInnerError(error.message);
    } else {
      setTimeout(() => {
        setInnerError('');
      }, 300);
    }
  }, [error]);

  React.useEffect(() => {
    if(router.pathname === '/map-website'){
      setThemeMode('light')
    } else {
      setThemeMode(localStorage.getItem('mode'));
    }
  }, []);

  const labelClasses = oneLine`
  md:text-sm
  text-body
  dark:text-dark-400
  absolute
  animated origin-0
  top-3.5 left-3.5
  pointer-events-none
  ${innerError ? 'text-danger-500' : ''}
  ${labelUp || val ? '-translate-y-8 left-3.5' : ''}
  `;

  const wrapperClasses = oneLine`
    relative
    ${innerError ? 'text-danger-500' : ''}
  `;

  const onFocus = () => {
    !val && setLabelUp(true);
  };

  const onBlur = () => {
    !val && setLabelUp(false);
  };

  const onCloseLabel = (valueSelected: any) => {
    const objOrArray = valueSelected && (!!Object.keys(valueSelected) || !!valueSelected.length);
    setLabelUp(!valueSelected ? false : objOrArray);
    setVal(valueSelected);
    onChange && onChange(valueSelected);
  };

  return (
    <div className={`relative select-box-div inline-block w-full`}>
      <div className={`${wrapperClasses} ${label ? 'mb-7 mt-6' : ''}`}>
        {isAsync ? (
          <>
            <AsyncSelect
              className="select-box"
              loadOptions={q => {
                if (getAsyncOptions) {
                  return getAsyncOptions(q);
                } 
              }}
              isClearable={isClearable}
              name={selName}
              cacheOptions={false}
              classNamePrefix={classNamePrefix}
              isMulti={isMulti}
              closeMenuOnSelect={!isMulti}
              defaultOptions={options}
              hideSelectedOptions={false}
              maxMenuHeight={maxMenuHeight}
              placeholder={label ? '' : placeholderText}
              defaultValue={defaultValue}
              value={value}
              isDisabled={disabled}
              ref={innerRef}
              onChange={onCloseLabel}
              onFocus={onFocus}
              onBlur={onBlur}
              menuPlacement="auto"
              data-cy={dataCy}
              components={{ DropdownIndicator }}
              styles={isMonday ? (themeMode === 'darkMode' ? styleMonday : styleMondayDark) : borderLight ? (themeMode === 'darkMode' ? styleDark : styleLightBorder) : (themeMode === 'darkMode' ? styleDark : style)}
              noOptionsMessage={inputValue => {
                if (inputValue.inputValue.length > 0)
                  return intl.formatMessage({
                    defaultMessage: 'No result found',
                    id: 'Error.NoResultFund',
                  });
                else
                  return intl.formatMessage({
                    defaultMessage: 'Start typing to search',
                    id: 'Error.StartTyping',
                  });
              }}
            />
          </>
        ) : (
          <Select
            aria-labelledby={selName}
            className={`select-box`}
            options={options}
            isClearable={isClearable}
            name={selName}
            classNamePrefix={classNamePrefix}
            menuPlacement="auto"
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti}
            hideSelectedOptions={false}
            maxMenuHeight={maxMenuHeight}
            placeholder={label ? '' : placeholderText}
            defaultValue={defaultValue}
            isDisabled={disabled}
            components={{ DropdownIndicator }}
            ref={innerRef}
            onChange={onCloseLabel}
            onFocus={onFocus}
            data-cy={dataCy}
            onBlur={onBlur}
            styles={borderLight ? (themeMode === 'darkMode' ? styleDark : styleLightBorder) : (themeMode === 'darkMode' ? styleDark : style)}
          />
        )}
          {label && (     
            <div className={`text-dark-300 w-fit text-xs pt-2 pl-3.5 space-x-1`}>
             <p className="text-dark-default dark:text-dark-300 inline">{hint}</p>
           </div>
          )}
        {(label && (
          <label id={selName} className={labelClasses}>
            {label}
          </label>
        )) ||
          null}
      </div>
      <div
        className={`text-danger-500 w-fit text-xs pt-2 space-x-1 collapsible-error ${error ? 'expanded' : 'hidden'}`}
      >
        <p className="inline">{innerError}</p>
      </div>
    </div>
  );
};

InputSelect.defaultProps = {
  disabled: false,
  isAsync: false,
  validation: {},
};
export default InputSelect;
