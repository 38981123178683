import React, { useState } from 'react';
import { Input, Checkbox } from '../atoms';
import Popover from '../atoms/popover/popover';
import { useRouter } from 'next/router';
import { ChevronDown, Filter2 } from 'react-iconly';

const checkboxes = [
  {
    name: 'Completed',
    value: 'completed',
  },
  {
    name: 'Pending',
    value: 'pending',
  },
  {
    name: 'Processing',
    value: 'processing',
  },
  {
    name: 'Running',
    value: 'running',
  },
  {
    name: 'Paused',
    value: 'paused',
  },
  {
    name: 'Rejected',
    value: 'rejected',
  },
  {
    name: 'Draft',
    value: 'draft',
  },
];

const campaignTypeOptions = [
  {
    name: 'Bulk Buying',
    value: 'Bulk Buying',
  },
  {
    name: 'Hyper Local',
    value: 'Hyper Local',
  },
];

type IPropsCategoryMenu = {
  filters: any;
  setFilters: (filter: string[]) => void;
};

const CategoryMenu = ({ filters, setFilters }: IPropsCategoryMenu) => {
  const isChecked = (value: string): boolean => {
    return filters.indexOf(value) > -1;
  };

  return (
    <ul className="popoever-cart-menu-items">
      {checkboxes.map((el, index: number) => {
        return (
          <li key={index}>
            <Checkbox
              name="primary"
              defaultChecked={isChecked(el.value)}
              onChange={(event: any) => {
                const f = [...filters];
                if (event.target.checked) {
                  f.push(el.value);
                } else {
                  let i = f.indexOf(el.value);
                  if (i > -1) {
                    f.splice(i, 1);
                  }
                }
                setFilters(f);
              }}
              labelColor="dark"
              label={el.name}
            />
          </li>
        );
      })}
    </ul>
  );
};

const CampaignTypeMenu = ({ filters, setFilters }: IPropsCategoryMenu) => {
  const isChecked = (value: string): boolean => {
    return filters.indexOf(value) > -1;
  };

  return (
    <ul className="popoever-cart-menu-items">
      {campaignTypeOptions.map((el, index: number) => {
        return (
          <li key={index}>
            <Checkbox
              name="primary"
              defaultChecked={isChecked(el.value)}
              onChange={(event: any) => {
                const f = [...filters];
                if (event.target.checked) {
                  f.push(el.value);
                } else {
                  let i = f.indexOf(el.value);
                  if (i > -1) {
                    f.splice(i, 1);
                  }
                }
                setFilters(f);
              }}
              labelColor="dark"
              label={el.name}
            />
          </li>
        );
      })}
    </ul>
  );
};

const FilterCampaignList = ({ onSearch }: { onSearch: (filters: string[], campaignTypes: string[]) => void }) => {
  const [showSearch, setShowSearch] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<string[]>([]);
  const [campaignTypeFilters, setCampaignTypeFilters] = React.useState<string[]>([]);
  const router = useRouter();

  React.useEffect(() => {
    if (router.query && router.query.filters && router.query.filters.toString().length > 0) {
      const currentFilters = router.query.filters.toString().split(',');
      setFilters(currentFilters);
      onSearch(currentFilters, campaignTypeFilters); // Initial filter state
    }
    if (router.query && router.query.campaignType && router.query.campaignType.toString().length > 0) {
      const currentCampaignTypes = router.query.campaignType.toString().split(',');
      setCampaignTypeFilters(currentCampaignTypes);
      onSearch(filters, currentCampaignTypes); 
    }
  }, []);

  const handleSearch = (updatedFilters: string[], updatedCampaignTypes: string[]) => {
    router.push(
      {
        pathname: '/mycampaigns',
        query: {
          filters: updatedFilters.join(','),
          campaignType: updatedCampaignTypes.join(','),
        },
      },
      undefined,
      { shallow: true }
    );

    onSearch(updatedFilters, updatedCampaignTypes);
  };

  return (
    <div className='flex flex-row items-center gap-8'>
      <Popover
        className="rightbottom w-6/12"
        handler={
          <>
            <div
              className={`input-simulate hidden md:flex items-center justify-between text-body dark:text-dark-300 flex-1 ${
                showSearch ? 'active' : ''
              }`}
              onClick={() => {
                setShowSearch(!showSearch);
              }}
            >
              <label className="cursor-pointer dark:text-dark-400">
                Statuses:
                <span className="mr-2 text-primary-500 text-bold capitalize-fl">
                  &nbsp;
                  {filters.length <= 1 ? filters[0] || 'All Statuses' : filters.length + ' Statuses'}
                </span>
              </label>
              <ChevronDown set="light" size="small" />
            </div>
            <div className="box-content px-4 py-3 border dark:border-dark-400 md:hidden text-body dark:text-dark-400 rounded-3xl">
              <Filter2 set="light" />
            </div>
          </>
        }
        content={
          <CategoryMenu
            filters={filters}
            setFilters={(f) => {
              setFilters(f);
              handleSearch(f, campaignTypeFilters);
            }}
          />
        }
      />

      <Popover
        className="rightbottom w-6/12"
        handler={
          <>
            <div
              className={`input-simulate hidden md:flex items-center justify-between text-body dark:text-dark-300 flex-1 ${
                showSearch ? 'active' : ''
              }`}
              onClick={() => {
                setShowSearch(!showSearch);
              }}
            >
              <label className="cursor-pointer dark:text-dark-400">
                Campaign type:
                <span className="mr-2 text-primary-500 text-bold capitalize-fl">
                  &nbsp;
                  {campaignTypeFilters.length <= 1
                    ? campaignTypeFilters[0] || 'All Types'
                    : campaignTypeFilters.length + ' Types'}
                </span>
              </label>
              <ChevronDown set="light" size="small" />
            </div>
            <div className="box-content px-4 py-3 border dark:border-dark-400 md:hidden text-body dark:text-dark-400 rounded-3xl">
              <Filter2 set="light" />
            </div>
          </>
        }
        content={
          <CampaignTypeMenu
            filters={campaignTypeFilters}
            setFilters={(f) => {
              setCampaignTypeFilters(f);
              handleSearch(filters, f);
            }}
          />
        }
      />
    </div>
  );
};

export default FilterCampaignList;
