import { createContext, ReactNode, useContext, useReducer } from 'react';
import MondayUtils from '@utils/monday';
import { MondayCampaign, MondayLocation, POI } from '@api/users/TpsApi';
import { ComponentVariant } from 'components/common/organisms/Monday/components/EmptySelection';
import { IContentType, ILocationFull } from '../../interface/ILocation';
import { v4 as uuidv4 } from 'uuid';
import { MondaySchedule, ScheduleDates } from './monday.type';

interface PricingResult {
  [locationId: string]: {
    totalPrice: string;
    slotIds: string[];
    playsPerHour: number;
  };
}
export interface IMondayMedia {
  resolution: [number, number];
  duration: number;
  type: IContentType;
  aspectRatio: string;
  orientation: string;
  formats: string[];
}

export type MondayState = {
  uuid: string;
  name: string;
  industry: string;
  type: string;
  step: number;
  refetchData: number;
  price: number;
  poi: POI[];
  selectedCampaign: '';
  selectedCampaignIndex: number | null;
  schedule: MondaySchedule[];
  mondayCampaigns?: MondayCampaign[];
  campaignLocations: ILocationFull[];
  locations: MondayLocation[];
  usedComponent: ComponentVariant[];
  newGroup: boolean;
  locationsPrice: PricingResult;
  editSchedule: string | null;
  sentence: {
    [key: string]: any;
  };
};
type Dispatch = (action: Action) => void;
type Action =
  | {
      type: 'addSentence';
      payload: {
        sentence: {
          [key: string]: any;
        };
      };
    }
  | { type: 'hydrate'; payload: { payload: MondayState } }
  | { type: 'removeFromSentence'; payload: { key: string; index: number } }
  | { type: 'newGroup' }
  | { type: 'editSchedule'; payload: { uuid: string; schedule: ScheduleDates[]; slots: Array<string> } }
  | { type: 'deleteGroup'; payload: { uuid: string } }
  | { type: 'increaseStep'; payload: { step: number } }
  | { type: 'removeFromSchedule'; payload: { locationIds: string[] } }
  | { type: 'moveLocationsToSchedule'; payload: { locationIds: string[]; targetScheduleId: string } }
  | { type: 'addNewSchedule'; payload: { locationIds: string[] } }
  | { type: 'showSchedule'; payload: { uuid: string } }
  | { type: 'closeNewGroup' }
  | { type: 'setPrice'; payload: { price: number } }
  | { type: 'closeSchedule' }
  | { type: 'calculatePrice'; payload: { location: PricingResult } }
  | { type: 'editTitle'; payload: { uuid: string; title: string } }
  | { type: 'updateSchedulePph'; payload: { uuid: string; pph: number } }
  | { type: 'resetUpdatePph'; payload: { uuid: string } }
  | { type: 'addNewOption'; payload: { component: ComponentVariant } }
  | { type: 'replaceUsedComponent'; payload: { key: string; index: number; value: string } }
  | { type: 'setUsedComponent'; payload: { usedComponent: ComponentVariant[] } }
  | { type: 'setCampaignType'; payload: { selectedCampaign: string; selectedCampaignIndex: number | null } }
  | { type: 'setPoi'; payload: { poi: POI[] } }
  | { type: 'setLocations'; payload: { locations: MondayLocation[] } }
  | { type: 'setCampaignLocations'; payload: { locations: ILocationFull[] } }
  | { type: 'setMondayCampaigns'; payload: { mondayCampaigns: MondayCampaign[] } }
  | { type: 'setBasic'; payload: { uuid: string; name: string; step: number; type: string; industry: string } };
type TMondayContext = { initialState: MondayState; state: MondayState; dispatch: Dispatch };
const defaultSchedule: MondaySchedule = {
  name: 'Default schedule group',
  pph: 0,
  defaultPph: 0,
  newPph: 0,
  locations: [],
  uuid: 'default',
  slots: [],
  schedule: [
    {
      startDate: 0,
      endDate: 0,
      customDates: [],
    },
  ],
};

const initialState: MondayState = {
  uuid: '',
  name: '',
  refetchData: 0,
  industry: '',
  type: '',
  price: 0,
  selectedCampaign: '',
  selectedCampaignIndex: null,
  sentence: {},
  usedComponent: [ComponentVariant.IN],
  step: 0,
  editSchedule: null,
  campaignLocations: [],
  poi: [],
  newGroup: false,
  locationsPrice: {},
  mondayCampaigns: [],
  locations: [],
  schedule: [defaultSchedule],
};

const MondayContext = createContext<TMondayContext>({
  initialState: initialState,
  state: initialState,
  dispatch: () => {},
});

const reducer = (state: MondayState, action: Action) => {
  switch (action.type) {
    case 'hydrate': {
      const schedule = action.payload.payload.schedule?.map(el => {
        return { ...el, newPph: el.pph };
      }) || [defaultSchedule];

      return { ...action.payload.payload, schedule, refetchData: state.refetchData + 1 };
    }
    case 'addSentence': {
      return { ...state, refetchData: state.refetchData + 1, sentence: { ...action.payload.sentence } };
    }
    case 'removeFromSentence': {
      const oldSentence = { ...state.sentence };
      delete oldSentence[action.payload.key];
      const oldUsedComponent = [...state.usedComponent];
      oldUsedComponent.splice(action.payload.index, 1);
      // Adjust the keys after the deleted one by decrementing their indices
      const returnSentence: {
        [key: string]: any;
      } = {};

      Object.keys(oldSentence).forEach(key => {
        const index = key.split('_').pop();
        if (index) {
          if (parseInt(index, 10) > action.payload.index) {
            const newKey = key.replace(`_${index}`, `_${parseInt(index, 10) - 1}`);
            returnSentence[newKey] = oldSentence[key];
          } else {
            returnSentence[key] = oldSentence[key]; // Fixed line
          }
        }
      });

      return {
        ...state,
        refetchData: state.refetchData + 1,
        sentence: { ...returnSentence },
        usedComponent: oldUsedComponent,
      };
    }
    case 'showSchedule':
      return { ...state, editSchedule: action.payload.uuid };
    case 'closeSchedule':
      return { ...state, editSchedule: null };
    case 'calculatePrice':
      return {
        ...state,
        price: (state.price || 1) + 1,
        locationsPrice: {
          ...state.locationsPrice,
          ...action.payload,
        },
      };
    case 'setCampaignType': {
      const locations = state.mondayCampaigns?.find(el => el.name === action.payload.selectedCampaign)?.data;

      return {
        ...state,
        selectedCampaign: action.payload.selectedCampaign,
        selectedCampaignIndex: action.payload.selectedCampaignIndex,
        locations: locations,
      };
    }
    case 'setPoi': {
      return { ...state, poi: action.payload.poi };
    }
    case 'setCampaignLocations': {
      const campaignDefaultData = state.mondayCampaigns?.find(el => el.name === state.selectedCampaign);
      defaultSchedule.locations = action.payload.locations;
      defaultSchedule.pph = campaignDefaultData?.pph || 10;
      defaultSchedule.defaultPph = campaignDefaultData?.pph || 10;
      defaultSchedule.uuid = uuidv4();
      defaultSchedule.isDefault = true;
      defaultSchedule.newPph = campaignDefaultData?.pph || 10;
      const foundDuration = Object.keys(state.sentence).filter(key => key.startsWith('for a duration')); // CHECK FOR UPDATED KEY (CE AI FACUT GABI???)
      const duration = foundDuration?.[0] ? state.sentence[foundDuration?.[0]] : '7';
      const { startDateUnix, endDateUnix } = MondayUtils.calculateStartEndDate(duration);
      defaultSchedule.slots = MondayUtils.calculateSlots(startDateUnix, endDateUnix, 8);
      defaultSchedule.schedule = [
        {
          startDate: startDateUnix,
          endDate: endDateUnix,
          customDates: [],
        },
      ];

      return { ...state, schedule: [defaultSchedule], campaignLocations: action.payload.locations };
    }
    case 'increaseStep':
      return { ...state, step: action.payload.step };
    case 'editSchedule':
      {
        const newSchedule = state.schedule.map(el => {
          if (el.uuid === action.payload.uuid) {
            return { ...el, schedule: action.payload.schedule, slots: action.payload.slots };
          }
          return el;
        });
        return { ...state, schedule: newSchedule, editSchedule: null };
      }
      break;
    case 'setLocations': {
      return {
        ...state,

        locations: action.payload.locations,
      };
    }
    case 'deleteGroup': {
      const deletedGroup = state.schedule.find(el => el.uuid === action.payload.uuid);
      const newSchedule = state.schedule.filter(el => el.uuid !== action.payload.uuid);
      if (!deletedGroup) {
        return state;
      }
      const defaultGroupIndex = newSchedule.findIndex(el => el.isDefault);

      if (defaultGroupIndex !== -1) {
        const existingLocations = newSchedule[defaultGroupIndex].locations || [];
        const deletedGroupLocations = deletedGroup.locations || [];
        const mergedLocations = [...deletedGroupLocations, ...existingLocations];

        newSchedule[defaultGroupIndex].locations = mergedLocations.filter(
          (loc, index, self) => index === self.findIndex(l => l.uuid === loc.uuid)
        );
      }

      return { ...state, schedule: newSchedule };
    }

    case 'removeFromSchedule': {
      const { locationIds } = action.payload;

      let updatedSchedule = state.schedule.map(scheduleItem => {
        const updatedLocations = scheduleItem.locations.filter(loc => !locationIds.includes(loc.uuid));
        return {
          ...scheduleItem,
          newPph: scheduleItem.pph,
          locations: updatedLocations,
        };
      });

      updatedSchedule = updatedSchedule.filter(scheduleItem => scheduleItem.locations.length > 0);
      const locationsPrice = { ...state.locationsPrice };
      locationIds.forEach(locId => {
        delete locationsPrice[locId];
      });

      return { ...state, schedule: updatedSchedule, locationsPrice };
    }

    case 'moveLocationsToSchedule': {
      const { locationIds, targetScheduleId } = action.payload;

      // Verificăm dacă targetScheduleId este valid
      const targetScheduleIndex = state.schedule.findIndex(el => el.uuid === targetScheduleId);
      if (targetScheduleIndex === -1) {
        return state; // Dacă targetScheduleId nu există, returnăm starea curentă fără modificări
      }

      // Parcurgem fiecare grup din schedule și eliminăm locațiile care trebuie mutate
      let updatedSchedule = state.schedule.map(scheduleItem => {
        const updatedLocations = scheduleItem.locations.filter(loc => !locationIds.includes(loc.uuid));
        return {
          ...scheduleItem,
          locations: updatedLocations,
        };
      });

      // Eliminăm din updatedSchedule orice grup care nu mai are locații
      updatedSchedule = updatedSchedule.filter(scheduleItem => scheduleItem.locations.length > 0);

      // Găsim locațiile care trebuie mutate (înainte de a fi eliminate din grupurile curente)
      const locationsToMove = state.schedule.flatMap(scheduleItem =>
        scheduleItem.locations.filter(loc => locationIds.includes(loc.uuid))
      );

      // Adăugăm locațiile mutate în targetSchedule
      const targetSchedule = updatedSchedule[targetScheduleIndex];
      const mergedLocations = [...targetSchedule.locations, ...locationsToMove];

      // Eliminăm duplicatele (dacă există)
      const uniqueLocations = mergedLocations.filter(
        (loc, index, self) => index === self.findIndex(l => l.uuid === loc.uuid)
      );

      // Actualizăm locațiile în targetSchedule
      updatedSchedule[targetScheduleIndex] = {
        ...targetSchedule,
        locations: uniqueLocations,
      };

      // Returnăm starea actualizată
      return { ...state, schedule: updatedSchedule };
    }

    case 'addNewSchedule': {
      const defaultGroup = state.schedule.find(el => el.isDefault);
      const locations = state.campaignLocations.filter(loc => action.payload.locationIds.includes(loc.uuid));
      const newSchedule = {
        name: `New schedule group ${state.schedule.length}`,
        pph: defaultGroup?.pph || 10,
        defaultPph: defaultGroup?.pph || 10,
        locations: locations,
        uuid: uuidv4(),
        slots: defaultGroup?.slots || [],
        schedule: [
          {
            startDate: defaultGroup?.schedule[0].startDate || 0,
            endDate: defaultGroup?.schedule[0].endDate || 0,
            customDates: [],
          },
        ],
      };

      //remove the locations from other groups
      const oldSchedule = state.schedule.map(el => {
        const newLocations = el.locations.filter(loc => !action.payload.locationIds.includes(loc.uuid));
        return { ...el, locations: newLocations };
      });

      const completSchedule = [...oldSchedule, newSchedule].filter(sch => sch.locations.length > 0);

      return { ...state, schedule: [...completSchedule] };
    }
    case 'newGroup':
      return { ...state, newGroup: true };
    case 'closeNewGroup':
      return { ...state, newGroup: false };

    case 'resetUpdatePph': {
      const newSchedule = state.schedule.map(el => {
        if (el.uuid === action.payload.uuid) {
          return { ...el, newPph: 0 };
        }
        return el;
      });
      return { ...state, schedule: newSchedule };
    }
    case 'updateSchedulePph': {
      const newSchedule = state.schedule.map(el => {
        if (el.uuid === action.payload.uuid) {
          return { ...el, newPph: action.payload.pph, pph: action.payload.pph };
        }
        return el;
      });
      return { ...state, schedule: newSchedule };
    }
    case 'editTitle': {
      const newSchedule = state.schedule.map(el => {
        if (el.uuid === action.payload.uuid) {
          return { ...el, name: action.payload.title };
        }
        return el;
      });
      return { ...state, schedule: newSchedule };
    }

    case 'setPrice':
      return { ...state, price: action.payload.price };

    case 'addNewOption': {
      return { ...state, usedComponent: [...state.usedComponent, action.payload.component] };
    }
    case 'replaceUsedComponent': {
      const { key, index, value } = action.payload;

      const newUsedComponent = [...state.usedComponent];
      newUsedComponent[index] = value as ComponentVariant;
      //replace the key with the new value and delete the old one
      const oldSentence = { ...state.sentence };
      delete oldSentence[key];
      oldSentence[`${value}_${index}`] = '';

      return { ...state, usedComponent: newUsedComponent, sentence: { ...oldSentence } };
    }
    case 'setUsedComponent': {
      return { ...state, usedComponent: action.payload.usedComponent };
    }
    case 'setMondayCampaigns': {
      return { ...state, mondayCampaigns: action.payload.mondayCampaigns };
    }
    case 'setBasic': {
      return {
        ...initialState,
        step: action.payload.step,
        uuid: action.payload.uuid,
        name: action.payload.name,
        industry: action.payload.industry,
        type: action.payload.type,
      };
    }
    default:
      return { ...state };
  }
  return { ...state };
};

type TMondayProvider = {
  children: ReactNode;
};

const MondayProvider = ({ children }: TMondayProvider) => {
  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  return <MondayContext.Provider value={{ initialState, state, dispatch }}>{children}</MondayContext.Provider>;
};

const useMondayContext = (): TMondayContext => {
  const context = useContext(MondayContext);
  return context;
};

export { MondayProvider, useMondayContext, MondayContext };
